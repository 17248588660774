import { computed, createVNode, defineComponent, getCurrentInstance, onActivated, onMounted, reactive, ref, toRaw, toRefs, VNode } from 'vue'
import moment, { Moment } from 'moment'
import { checkAccess, ExportXlsx, IsNullOrUndefined } from '@/utils/common'
import { CityExaminerNumberDetail, EnumSpeakingType, AuditEntityTypeEnum, ExaminerRoleEnum, SystemStatusEnum, ExaminerLastSessionStatusEnum, ExaminerMonitorStatusEnum, ExaminerProfileStatusEnum, ExaminerCertificationStatusEnum, ExportExaminerArrangementQuery, FileResponse, GetAllRegionRep, GetByIdExaminerRep, PagedResultOfSearchExaminerHubRep, PagedResultOfSearchExaminerRep, SearchDeploymentF2FActionOperationLogQuery, SearchDeploymentVcsActionOperationLogQuery, SearchExaminerQuery, SearchExaminerRep, AuditEntityLogRep, GetExaminerLogsByIdQuery, WorkStatusEnum } from '@/api-client/client'
import { addTimezoneByDbNameForce } from '@/utils/DateExtensions'
import { _Client } from '@/api-client'
import Pagination from '@/components/Pagination/index.vue'
import LogDetail from '@/components/LogDetail/index.vue'
import { useRouter } from 'vue-router'
import { message, Modal } from 'ant-design-vue'
import { h } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { LogDetailModel, LogModel } from '@/api-client/modal';
import { Form } from 'ant-design-vue';

const useForm = Form.useForm;
export default defineComponent({
  components: {
    Pagination,
    LogDetail
  },
  setup() {
    const timeMoment = moment
    const { push } = useRouter();
    const internalInstance = getCurrentInstance();
    const isNullOrUndefined = IsNullOrUndefined
    const examinerRoleEnum = ExaminerRoleEnum
    const examinerSystemStatusEnum = SystemStatusEnum
    const examinerProfileStatusEnum = ExaminerProfileStatusEnum
    const examinerMonitorStatusEnum = ExaminerMonitorStatusEnum
    const examinerLastSessionStatusEnum = ExaminerLastSessionStatusEnum
    const examinerCertificationStatusEnum = ExaminerCertificationStatusEnum
    const workStatusEnum = WorkStatusEnum
    const examienrTableLoading = ref<boolean>(false)
    const logDetailRef = ref(null)

    const columns = [
      {
        title: 'Region',
        dataIndex: 'regionName',
        width: 120
      },
      {
        title: 'Examiner No.',
        dataIndex: 'no',
        width: 100
      },
      {
        title: 'Examiner Name',
        dataIndex: 'examinerName',
        width: 110
      },
      {
        title: 'Initial',
        dataIndex: 'initial',
        width: 150
      },
      {
        title: 'Base city',
        dataIndex: 'baseCity',
        width: 200
      },
      {
        title: 'Spk Profile Status',
        dataIndex: 'speakingProfileStatus',
        width: 120
      },
      {
        title: 'Spk Monitor Status',
        dataIndex: 'speakingMonitorStatus',
        width: 100
      },
      {
        title: 'Spk Last Session Status',
        dataIndex: 'speakingLastSessionStatus',
        width: 120
      },
      {
        title: 'Spk Certification Status',
        dataIndex: 'speakingCertificationStatus',
        width: 100
      },
      {
        title: 'Wrt Profile Status',
        dataIndex: 'writingProfileStatus',
        width: 120
      },
      {
        title: 'Wrt Monitor Status',
        dataIndex: 'writingMonitorStatus',
        width: 120
      },
      {
        title: 'Wrt Last Session Status',
        dataIndex: 'writingLastSessionStatus',
        width: 120
      },
      {
        title: 'Wrt Certification Status',
        dataIndex: 'writingCertificationStatus',
        width: 120
      },
      {
        title: 'System Status',
        dataIndex: 'examinerSystemStatus',
        width: 120
      },
      {
        title: 'Action',
        dataIndex: 'action',
        fixed: 'right',
        width: 200
      }
    ]

    const state = reactive<{
      regionList: GetAllRegionRep[],
      searchParames: SearchExaminerQuery,
      tableSource: PagedResultOfSearchExaminerRep,
      rulesRef: { [key: string]: any },
      searchParamesForm: SearchExaminerQuery
    }>({
      regionList: new Array<GetAllRegionRep>(),
      searchParames: new SearchExaminerQuery({
        regionId: undefined,
        pageIndex: 1,
        pageSize: 10
      }),
      tableSource: new PagedResultOfSearchExaminerRep(),
      rulesRef: {
        examinerNo: [
          { required: false, message: "Please input examiner no" },
        ],
      },
      searchParamesForm: new SearchExaminerQuery(<SearchExaminerQuery>{examinerNo: undefined})
    })

    const logState = reactive<{ 
      logSearchParames: GetExaminerLogsByIdQuery, 
      logDetail: LogDetailModel ,
      showLogDialog:Function,
      getLogList:Function
    }>({
      logSearchParames: new GetExaminerLogsByIdQuery(<GetExaminerLogsByIdQuery>{pageIndex:1}),
      logDetail: new LogDetailModel(),

      showLogDialog: async(examinerId: string)=> {
        //   let hasEditPermission = await checkAccess(`${internalInstance!.appContext.config.globalProperties.$pageName}:${internalInstance!.appContext.config.globalProperties.$actionNames.ViewLog}`, examinerHub!.officeId)
        //   if (!hasEditPermission) { return }
          logState.logSearchParames.pageIndex =1;
          logState.logSearchParames.examinerId = examinerId
          await logState.getLogList()
        },

        getLogList:async ()=>{
          try {
            let logDetail = JSON.parse(JSON.stringify(await _Client.examinerClient.getExaminerLogsById(logState.logSearchParames)))
            if(!logDetail){
                return;
            }
            logDetail.list = logDetail.list?.map((logItem: { auditTime: Date; operation: string; userName: string; }) => {
              return new LogModel({
                createdTime: logItem.auditTime,
                operation: logItem.operation,
                userName: logItem.userName
              })
            })
            logState.logDetail = new LogDetailModel(logDetail)
          }
          finally {
            (logDetailRef.value as any).showLogModal = true
          }
        }
    })

    const { resetFields, validate, validateInfos } = useForm(state.searchParamesForm, state.rulesRef);

    // const labelCol = { span: 5 }
    // const wrapperCol = { span: 8 }  
    
    async function getRegionList() {
      state.regionList = await _Client.regionClient.getAll(true)
    }
    async function getExaminerProfileData() {
      try {
        examienrTableLoading.value = true
        state.tableSource = await _Client.examinerClient.search(state.searchParames)
      }
      finally {
        examienrTableLoading.value = false
      }
    }

    async function resetSearchParams() {
      state.searchParames = new SearchExaminerQuery()
      await getExaminerProfileData()
      // state.tableSource= new PagedResultOfSearchExaminerRep()
    }

    async function changeSearchParames(){
      state.searchParames.pageIndex = 1
      await getExaminerProfileData()
    };

    onActivated(() => {
      state.searchParames.pageIndex = 1
      getRegionList()
      getExaminerProfileData()
    })
    const tableActions = () => {

      async function goToDetailPage(searchExaminerDetail: SearchExaminerRep) {
        // let hasAssignViewPermission = await checkAccess(`${internalInstance!.appContext.config.globalProperties.$pageName}:${internalInstance!.appContext.config.globalProperties.$actionNames.AssignView}`, spkDeployment.regionId)
        // if (!hasAssignViewPermission) { return }
          let goToRouter = `/examinerManagement/examiner-detail/${searchExaminerDetail.id}`;
          push(goToRouter)
      }
  

    return {goToDetailPage};
  }

  
async function editNewExaminer(){
  // var 
}

async function roleChange(e: any){
  console.log(e);
  // var 
}

    return {
      examinerRoleEnum,
      examinerSystemStatusEnum,
      workStatusEnum,
      examinerProfileStatusEnum,
      examinerMonitorStatusEnum,
      examinerLastSessionStatusEnum,
      examinerCertificationStatusEnum,
      columns,
      ...toRefs(state),
      changeSearchParames,
      ...tableActions(),
      getExaminerProfileData,
      examienrTableLoading,
      resetSearchParams,
      validate,
      validateInfos,
      resetFields,
      logState,
      logDetailRef
    }
  }
})

